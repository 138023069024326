import { ZodError } from 'zod'
import { FetchError } from 'ofetch'
import { ApiException } from '~/clients'
import { AxiosError } from 'axios'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
    const toast = useToast()
    const route = useRoute()
    const t = useNuxtApp().$i18n.t

    const handleApiException = (errorHandlerError: any, toast: any) => {
      if (errorHandlerError.statusCode === 401 || errorHandlerError.status === 401) {
        showError({
          message: t('error.unauthorized'),
          statusCode: 401,
          fatal: true,
        })
        navigateToLogin(route.fullPath)
        return
      }

      if (errorHandlerError.statusCode === 403 || errorHandlerError.status === 403) {
        showError({
          message: t('error.forbidden'),
          statusCode: 403,
          fatal: true,
        })
        return
      }

      if (errorHandlerError.statusCode === 404 || errorHandlerError.status === 404) {
        showError({
          message: t('error.notFound'),
          statusCode: 404,
          fatal: true,
        })
        return
      }
      if (errorHandlerError.statusCode === 400 || errorHandlerError.status === 400) {
        console.warn('Bad request')
        return
      } else {
        toast.error(t('error.serverError'))
        return
      }
    }

    if (error?.cause instanceof ZodError) {
      // shouldn't happen
      console.log('Validation error')
      return
    }

    if (
      error.cause instanceof ApiException ||
      error.cause instanceof AxiosError ||
      error.cause instanceof FetchError ||
      error.type === 'onRequestError' ||
      error.type === 'onResponseError' ||
      error.statusCode
    ) {
      return handleApiException(error, toast)
    }

    return false
  }
})
