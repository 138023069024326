import { navigateToLogout } from '~/utils/navigateToLogin'
import _ from 'lodash'
import type { LocationForCurrentUserResponse, UserGroupsContext } from '~/clients'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    id: '0000-0000-0000-0000',
    name: 'user',
    roles: ['GUEST'] as string[],
    isLoggedIn: false,
    location: null as number | null,
    groups: [] as UserGroupsContext[],
  }),
  getters: {
    isEmployee({ roles }) {
      return roles.includes(AuthRole.QRAY_GMP)
    },
  },
  actions: {
    async logout() {
      return navigateToLogout()
    },
    adminGroups() {
      return this.groups.filter(
        (group) => group.typeName === 'Manager' && group.userCanBeGroupManager,
      )
    },
    isHavingAdminGroups() {
      return this.adminGroups().length > 0
    },
    isInRoles(values: string[]) {
      if (values.length === 0) return true
      return _.some(values, (x) => {
        return this.roles.includes(x)
      })
    },
    isHavingCorrectRoles() {
      return (
        this.roles?.length > 0 &&
        this.roles.some((role) => Object.values(AuthRole).includes(role)) === true
      )
    },
    async fetchLoggedInUser() {
      const client = useApi()
      const result = await client.readCurrentUser()
      this.name = result.name
      this.id = result.id
      this.roles = result.roles
      this.groups = result.groups

      const locations = await useApiClient<Promise<LocationForCurrentUserResponse>>(
        '/users/current/locations',
      )
      this.location = locations.values?.[0]?.id // Todo: check if we have locations and check what we need to do if we don't have locations?
      this.isLoggedIn = true
    },
  },
})
