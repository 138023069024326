export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.name === from.name) return

  const { $i18n } = useNuxtApp()

  const result = await $fetch<{ clientPrincipal: any }>('/.auth/me')

  if (result.clientPrincipal == null && !import.meta.dev) return navigateToLogin(to.fullPath)

  const authStore = useAuthStore()
  if (!authStore.isLoggedIn) {
    try {
      await authStore.fetchLoggedInUser()
      if (!authStore.isHavingCorrectRoles()) {
        throw new Error("User doesn't have the correct roles")
      }
    } catch (error) {
      console.error(error)
      throw createError({
        statusCode: 403,
        message: $i18n.t('error.unauthorized'),
      })
    }
  }

  return
})
