import { convertLineBreaks } from '~/utils'

export const useApiClient = <T>(
  request: Parameters<typeof $fetch<T>>[0],
  opts?: Parameters<typeof $fetch<T>>[1],
) => {
  const runtimeConfig = useRuntimeConfig()

  const header = runtimeConfig.public.FAKE_HEADER
  const baseUrl = runtimeConfig.public.API_BASE_URL

  return $fetch<T>(request, {
    baseURL: baseUrl,
    onRequestError: (error) => {
      throw {
        type: 'onRequestError', //mark the error type
        message: `${error.error.message}`, //todo friendlier messages
        statusCode: error.response?.status,
        data: error.response,
      }
    },
    onResponseError: (error) => {
      if (error.response.status !== 400) {
        throw {
          data: error.response,
          type: 'onResponseError', //mark the error type
          message: `${error.error?.message}`, //todo friendlier messages
          statusCode: error.response?.status,
          result: error.response._data,
        }
      }
    },
    headers: {
      ...opts?.headers,
      'x-ms-client-principal': header,
    },
    parseResponse: (response) => {
      try {
        if (typeof response === 'string' && response.length > 0) {
          return JSON.parse(convertLineBreaks(response))
        }
      } catch (e) {
        console.error(e)
      }

      return response
    },
    ...opts,
  })
}
