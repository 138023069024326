import Vue3Toastify, { toast, type ToastContainerOptions } from 'vue3-toastify'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    multiple: true,
    clearOnUrlChange: true,
    // newestOnTop: true,
    theme: 'colored',
    transition: 'slide',
    closeButton: false,
    closeOnClick: false,
    // autoClose: 500000,
    limit: 1,
  } as ToastContainerOptions)
  return {
    provide: { toast },
  }
})
