<script setup lang="ts">
  import type { NuxtError } from '#app'

  const props = defineProps({
    error: Object as () => NuxtError,
  })

  const handleError = () => clearError({ redirect: '/' })
  const { t } = useI18n()
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="flex flex-col justify-center items-center">
        <div class="max-w-md w-full space-y-8">
          <div class="flex flex-col items-center space-y-4">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-primary">
              {{ props.error?.statusCode }}
            </h2>
            <p class="mt-2 text-center text-xl text-gray-600">
              {{ props.error?.message ?? t('error.notFound') }}
            </p>
            <BasicBtn @click="handleError" size="regular">
              {{ t('error.returnToHome') }}
            </BasicBtn>
          </div>
        </div>
      </div>
    </NuxtLayout>
  </div>
</template>
